import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import close from "../../../assets/icon/close_grey.svg";
import * as Constants from "../../../constants/global";
import axios from "axios";
import { data } from "jquery";

import "../modal_sucursales/login_sucursales.css";

const LoginSucursales = (props) => {
  const [sucursales, setSucursales] = useState([]);
  const [sucursalesComp, setSucursalesComp] = useState([]);

  var sucursalesTarjeta = [];

  const handleClose = () => {
    document.querySelector(".modal-sucursales").classList.remove("show-modal");
  };

  const cargarSucursales = () => {
    /**obtenemos el usuarios logeado de tipo cliente cl */

    let nit = localStorage.getItem("usuario");
    let url = Constants.URL_BASE + Constants.WSCARGARSUCURSALES + "?nit=" + nit;
    console.log(url);
    axios.get(url, data).then((res) => {
      setSucursales(res.data);
    });
  };

  useEffect(() => {
    cargarSucursales();
  }, [localStorage.getItem("usuario")]);

  useEffect(() => {
    sucursalesTarjeta = sucursales.map((sucursal, id) => {
      return (
        <div
          className="card"
          onClick={() => handleSeleccionarSucursal(sucursal)}
        >
          <li>
            <ul>
              <b>Codigo: </b>
              {sucursal.Codigo}
            </ul>

            <ul>
              <b>Nombre: </b>
              {sucursal.Nombre}
            </ul>

            <ul>
              <b>Sucursal: </b>
              {sucursal.CodSucursal}
            </ul>
          </li>
        </div>
      );
    });
    setSucursalesComp(sucursalesTarjeta);
  }, [sucursales]);

  const handleSeleccionarSucursal = (sucursal) => {
    localStorage.setItem("codCliente", sucursal.Codigo);
    localStorage.setItem("sucursal", sucursal.CodSucursal);
    localStorage.setItem("nombrecliente", sucursal.Nombre);
    props.history.push("/catalogo");
  };

  return (
    <div className="modal modal-sucursales">
      <div className="modal-content">
        <div className="center">
          <img
            src="https://www.ramo.com.co/wp-content/uploads/2020/09/ramo-logo.png"
            className="imagen"
          ></img>
        </div>
        <div className="title">
          <span className="close-button" onClick={() => handleClose()}>
            <img src={close} width="15" height="15" alt="icon close" />
          </span>
          <p>Por favor seleccionar la sucursal correspondiente:</p>
        </div>
        <div className="scroll">{sucursalesComp}</div>
      </div>
    </div>
  );
};

export default withRouter(LoginSucursales);
