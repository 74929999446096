import React, { useState, useEffect } from "react";
import NavbarInicial from "../navbar/NavbarInicial";
import TabbarInicial from "../navbar/TabbarInicial";
import Filtros from "../navbar/Filtros";

import { ReactComponent as Search } from "../../assets/icon/search.svg";
import Encabezado from "./Encabezado";
import Axios from "axios";
import * as Constants from "../../constants/global";

export const Historico = () => {
  const [search, setSearch] = useState("");

  const [tipoSucursal, setTipoSucursal] = useState();
  const [regional, setRegional] = useState();
  const [agencia, setAgencia] = useState();
  const [zona, setZona] = useState();
  const [filtroUsuario, setFiltroUsuario] = useState();

  const [fechaInicial, setFechaInicial] = useState();
  const [fechaFinal, setFechaFinal] = useState();

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleBuscarCliente();
    }
  };

  const handleTipoSucursal = (item) => {
    setTipoSucursal(item);
  };

  const handleRegional = (item) => {
    setRegional(item);
  };

  const handleAgencia = (item) => {
    setAgencia(item);
  };

  const handleZona = (item) => {
    setZona(item);
  };

  const handleFiltroUsuario = (item) => {
    setFiltroUsuario(item);
  };

  const handleFiltros = (tipoSucursal, agencia, zona, filtroUsuario) => {
    setTipoSucursal(tipoSucursal);
    setAgencia(agencia);
    setZona(zona);
    setFiltroUsuario(filtroUsuario);
  };

  const handleFechaInicial = (item) => {
    setFechaInicial(item);
  };

  const handleFechaFinal = (item) => {
    setFechaFinal(item);
  };

  const handleBuscarCliente = () => {
    let busqueda = document.getElementById("search").value;
    setSearch(busqueda);
  };

  

  return (
    <React.Fragment>
      <div style={{ display: "inline", width: "100%" }}>
        <div className="navbar">
          <div className="navbar-header">
            <NavbarInicial item={4} />
            <div className="input-search">
              <input
                type="text"
                placeholder="Buscar cliente..."
                name="search"
                id="search"
                onKeyPress={handleKeyPress}
                onChange={handleBuscarCliente}
                // onChange={(e) => setSearch(e.target.value)}
                // value={search}>
              ></input>
              <div className="button-Search">
                <button onClick={() => alert("buscar")}>
                  <Search
                    className="svg-una-capa"
                    style={{ width: "25px", height: "25px" }}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <div className="main">
            <div className={"cliente-list padding-cliente-list"}>
              <React.Fragment>
                <div className="product-list">
                  <div className="item">
                    <Filtros
                      handleRegional={handleRegional}
                      handleAgencia={handleAgencia}
                      handleZona={handleZona}
                      handleTipoSucursal={handleTipoSucursal}
                      handleFiltros={handleFiltros}
                      handleFiltroUsuario={handleFiltroUsuario}
                      handleFechaInicial={handleFechaInicial}
                      handleFechaFinal={handleFechaFinal}
                      habilitarFecha={false}
                      habilitarFechaInicial={true}
                      habilitarFechaFinal={true}
                      habilitarTipo={true}
                      habilitarRegional={true}
                      habilitarAgencia={true}
                      habilitarZona={true}
                      habilitarUsuario={true}
                    />
                  </div>
                </div>
              </React.Fragment>
            </div>
          </div>
        </div>
        <Encabezado
          search={search}
          fechaInicial={fechaInicial}
          fechaFinal={fechaFinal}
          regional={regional}
          agencia={agencia}
          zona={zona}
          filtroUsuario={filtroUsuario}
        />

        <TabbarInicial item={4} />
      </div>
    </React.Fragment>
  );
};
