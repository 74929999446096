import axios from "axios";

export default axios.create({
  baseURL:
    /*PRD*/
    "https://supervisoresramo.cwmovilidad.com/RamoCanalModerno/",
    /*QAS*/
    // "https://mercadeo.celuwebdev.com/ApiRamoCanalAlternativo/",
  /*LOCAL*/
  // "http://localhost:36025/",
});
