import React from "react";
import { withRouter } from "react-router-dom";
import close from "../../assets/icon/close_grey.svg";
import Auth from "../../helpers/Auth";

import * as Constants from "../../constants/global";

import axios from "axios";

const CodigoActivacionModal = (props) => {
  const [codigoActivacion, setCodigoActivacion] = React.useState("");
  const [ischecked, setIsChecked] = React.useState();
  const [ischeckedHv, setIsCheckedHv] = React.useState();

  const handleClose = () => {
    document
      .querySelector(".modal-codigoactivacion")
      .classList.remove("show-modal");
  };

  const handleConfirmarCodigo = () => {
    let data = {
      Codigo: props.validacion.Codigo,
      CodigoVerficacion: codigoActivacion,
    };

    let url = Constants.URL_BASE + Constants.WSACTIVARCLIENTE;

    console.log(url);
    axios.post(url, data).then((res) => {
      console.log(data);
      console.log(res);
      if (res.data.Activado === 1) {
        alert(res.data.Mensaje);
        document
          .querySelector(".modal-codigoactivacion")
          .classList.remove("show-modal");
        handleLogin();
      } else {
        alert(res.data.Mensaje);
      }
    });
  };

  const handleLogin = () => {
    localStorage.setItem("usuario", props.nit);
    localStorage.setItem("tipoUsuario", "cl");
    if (localStorage.getItem("tipoUsuario") == "cl") {
      document.querySelector(".modal-sucursales").classList.add("show-modal");
    } else {
      Auth.authenticate(() => {
        props.history.push("/catalogo");
      });
    }
  };

  const handleCheck = (event) => {
    let isActive = event.target.checked;
    setIsChecked(isActive);
  };

  const handleCheckHv = (event) => {
    let isActive = event.target.checked;
    setIsCheckedHv(isActive);
  };

  return (
    <div className="modal modal-codigoactivacion">
      <div className="modal-content">
        <div className="title">
          <span className="close-button" onClick={() => handleClose()}>
            <img src={close} width="15" height="15" alt="icon close" />
          </span>
          <p>Activar Cuenta</p>
          <p style={{ color: "black", fontWeight: "unset", fontSize: "13px" }}>
            Por favor ingresa el código de activación que te hemos enviado.
          </p>
        </div>
        <div className="content sm no-padding">
          <input
            type="number"
            onChange={(e) => setCodigoActivacion(e.target.value)}
            value={codigoActivacion}
          ></input>
          <div style={{ display: "flex" }}>
            <input
              type="checkbox"
              onChange={(event) => handleCheck(event)}
              style={{ width: "15%" }}
            />
            <a
              href="https://mercadeo.celuwebdev.com/apiHarinera/TERMINOSYCONDICIONESEMART.pdf"
              style={{ margin: "auto" }}
              target="_blank"
            >
              Acepto Politica de Privacidad Emart
            </a>
          </div>
          <div style={{ display: "flex" }}>
            <input
              type="checkbox"
              onChange={(event) => handleCheckHv(event)}
              style={{ width: "15%" }}
            />
            <a
              href="https://mercadeo.celuwebdev.com/apiHarinera/TERMINOSYCONDICIONESHV.pdf"
              style={{ margin: "auto" }}
              target="_blank"
            >
              Acepto Terminos y Condiciones Harinera del Valle
            </a>
          </div>
        </div>

        <div className="footer">
          <button
            type="submit"
            className="btn-large"
            disabled={
              typeof ischecked !== "undefined" &&
              typeof ischeckedHv !== "undefined"
                ? ischecked && ischeckedHv && codigoActivacion.length == 7
                  ? false
                  : true
                : true
            }
            onClick={() => handleConfirmarCodigo()}
          >
            Enviar Código
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CodigoActivacionModal);
