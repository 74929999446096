import React, { useEffect, useState } from "react";
import NavbarInicial from "../navbar/NavbarInicial";
import TabbarInicial from "../navbar/TabbarInicial";
import styled, { createGlobalStyle, css } from "styled-components";
import PassIcon from "../../assets/icon/password.svg";
import Modal from "styled-react-modal";
import { ModalProvider } from "styled-react-modal";
import Axios from "axios";
import * as Constants from "../../constants/global";
import toast from "toasted-notes";

import { Bar } from "react-chartjs-2";

import imagecliente from "../../assets/img/cliente.png";

const SharedStyles = css`
  background-color: #eee;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 10px 0 20px 0;
  padding: 20px;
  box-sizing: border-box;
`;

const StyledFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 300px;
  margin: 20px;
`;

const StyledForm = styled.form`
  width: 100%;
  max-width: 400px;
  margin: auto;
  padding: 40px;
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
`;

const ContentHeader = styled.div`
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin: auto;
  padding: 10px 40px;
`;

const ContentBody = styled.div`
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin: auto;
  padding: 10px 40px;
`;

const ContentFooter = styled.div`
  display: grid;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  grid-template-columns: 0.2fr 0.8fr;
  column-gap: 1rem;
  width: 100%;
  max-width: 400px;
  margin: auto;
  padding: 40px;
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
`;

const StyledModal = Modal.styled`
  width: 30rem;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
`;

const StyledInput = styled.input`
  display: block;
  width: 100%;
  ${SharedStyles}
`;

const StyledButtons = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  column-gap: 1rem;
  justify-content: center;
  align-items: center;
`;

const StyleButton = styled.button`
  display: block;
  background-color: #009ae2;
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: 5px;
  height: 40px;
  padding: 0 20px;
  cursor: pointer;
  box-sizing: border-box;
`;

const StyledError = styled.div`
  color: red;
  font-weight: 800;
  margin: 0 0 40px 0;
`;

export const MyAccount = () => {
  let nombreUsuario = localStorage.getItem("nombreUsuario");

  let usuario = localStorage.getItem("usuario");
  let tipoUsuario = localStorage.getItem("tipoUsuario");
  let correoUsuario = localStorage.getItem("correoUsuario");

  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");
  const [clave, setClave] = useState("");

  const [presupuesto, setPresupuesto] = useState(0);
  const [ventas, setVentas] = useState(0);
  const [dropsize, setDropsize] = useState(0);

  const data = {
    labels: ["Presupuesto", "Pedidos", "Drop Size"],
    datasets: [
      {
        label: "Resumen del día",
        backgroundColor: "rgba(234, 11, 42, 1)",
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(234, 11, 42, 0.2)",
        hoverBorderColor: "rgba(0,0,0,0.2)",
        data: [presupuesto, ventas, dropsize],
      },
    ],
  };

  const options = {
    maintainAspectRatio: true,
    responsive: true,
  };

  function toggleModal(e) {
    setIsOpen(!isOpen);
    setError("");
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (clave === "") {
      setError(`La contraseña es obligatoria`);
      return;
    }

    setError("");
    let url = Constants.URL_BASE + Constants.WSUPDATEPASSWORD;
    const data = {
      Codigo: usuario,
      Clave: clave,
    };
    Axios.post(url, data)
      .then((res) => {
        // console.log(res.data);
        toast.notify("Contraseña actualizada correctamente");
        setIsOpen(false);
      })
      .catch((err) => {
        toast.notify("Error al actualizar la contraseña");
      });
  };

  const loadDatos = () => {
    let url =
      Constants.URL_BASE +
      Constants.WSDATOSESTADISTICAS +
      "?usuario=" +
      usuario +
      "&tipousuario=" +
      tipoUsuario;

    Axios.get(url)
      .then((res) => {
        setPresupuesto(res.data[0].presupuesto);
        setVentas(res.data[0].ventas);
        setDropsize(res.data[0].dropsize);
      })
      .catch((err) => {
        toast.notify("Error al cargar los datos del resumen diario");
      });
  };

  useEffect(() => {
    loadDatos();
  }, []);

  return (
    <React.Fragment>
      <ModalProvider>
        <div className="navbar">
          <div className="navbar-header">
            <NavbarInicial item={5} />
          </div>
        </div>
        <br />
        <div style={{ margin: "auto", width: "100%" }}>
          <div className={"CardPassword"}>
            <div className={"seccion"}>
              <ContentHeader>
                <img src={imagecliente} style={{ width: "60%" }} />
              </ContentHeader>
              <ContentBody>
                <p>
                  <b>Perfil</b>
                </p>
                <p>{nombreUsuario}</p>
                <p>
                  <b>Correo</b>
                </p>
                <p>{correoUsuario}</p>
              </ContentBody>
              <ContentFooter onClick={() => setIsOpen(true)}>
                <img
                  src={PassIcon}
                  alt="icon gear"
                  style={{ width: "30px", height: "30px" }}
                />
                <h3>Cambio Contraseña</h3>
              </ContentFooter>
            </div>
            <div className={"seccion"}>
              <Bar data={data} options={options} />
            </div>
          </div>
          <StyledModal
            isOpen={isOpen}
            onBackgroundClick={toggleModal}
            onEscapeKeydown={toggleModal}
          >
            <StyledForm onSubmit={handleSubmit}>
              <h2>Cambio de contraseña</h2>
              {/* <label htmlFor="name">Name</label> */}
              <StyledInput
                placeholder="introduce una contraseña"
                type="password"
                name="clave"
                value={clave}
                onChange={(e) => setClave(e.target.value)}
              />
              {error && (
                <StyledError>
                  <p>{error}</p>
                </StyledError>
              )}
              <StyledButtons>
                <StyleButton type="submit">Confirmar</StyleButton>
                <StyleButton type="button" onClick={toggleModal}>
                  Cancelar
                </StyleButton>
              </StyledButtons>
            </StyledForm>
          </StyledModal>
        </div>
      </ModalProvider>

      <TabbarInicial item={5} />
    </React.Fragment>
  );
};
