import React, { useState, useEffect, useImperativeHandle } from "react";
import Navbar from "../navbar/Navbar";
import Tabbar from "../navbar/Tabbar";
import $ from "jquery";
import * as Constants from "../../constants/global";
import noimage from "../../assets/img/noimage.png";
import axios from "axios";
import { traerProductos } from "../../actions";
import { connect } from "react-redux";
import Progress from "../util/Progress";
import descuento from "../../assets/img/descuento.png";

//Leer Excel
import { ExcelRenderer, OutTable } from "react-excel-renderer";

//descargar excel
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Excel = (props) => {
  const [loader, setLoader] = React.useState(false);
  const [totalNavbar, setTotalNavbar] = useState();
  const [cantidad, setCantidad] = useState();
  const [carrito, setCarrito] = useState({});
  const [productos, setProductos] = useState();
  const [arrayProducto, setArrayProductos] = useState({ result: [] });

  let sucursal = localStorage.getItem("sucursal");
  let codCliente = localStorage.getItem("codCliente");
  let usuario = localStorage.getItem("usuario");
  let empresa = JSON.parse(localStorage.getItem("empresa"));
  var columns = ["Código", "Cantidad"];

  const uploadFile = async (event) => {
    let fileObj = event.target.files[0];

    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
      } else {
        if (resp.cols.length == 2) {
          if (resp.rows[0][0] == "Código" && resp.rows[0][1] == "Cantidad") {
            setLoader(true);

            Object.keys(resp.rows).map((item) =>
              UsersFormatter(item, resp, resp.rows.length - 1)
            );
          } else {
            alert(
              "Los encabezados de las columnas no coinciden con las del archivo original"
            );
          }
        } else {
          alert(
            "El archivo cargado no coincide con la estructura del archivo original"
          );
        }
      }
    });
  };

  function get_user(url) {
    // return this promise
    return axios.get(url).then((res) => {
      return res.data;
    });
  }

  const UsersFormatter = async (item, resp, limit, array) => {
    var found = "";
    let url =
      Constants.URL_BASE +
      Constants.WSPRODUCTO +
      "?origen=" +
      "1" +
      "&codCliente=" +
      codCliente +
      "&sucursal=" +
      sucursal +
      "&usuario=" +
      usuario;

    var codProducto = resp.rows[item][0]
      ? resp.rows[item][0].toString().trim()
      : undefined;

    found = codProducto ? props.productosTodos[codProducto] : undefined;

    if (item != 0) {
      url += "&search=" + codProducto;

      return await get_user(url).then((data) => {
        if (codProducto) {
          if (data.result.length > 0) {
            if (resp.rows[item][1] <= found.cantidadInventario) {
              data.result[0].cantidad = resp.rows[item][1];
            } else {
              data.result[0].cantidad = found.cantidadInventario;
            }
          }

          if (arrayProducto) {
            if (arrayProducto.result) {
              let combine = arrayProducto.result.concat(data.result);
              arrayProducto.result = combine;
            }
          } else {
            setArrayProductos(data);
          }
          setProductos(arrayProducto.result);
          if (item == limit) {
            setLoader(false);
          }
        } else {
          if (item == limit) {
            setLoader(false);
          }
        }
      });
    } else {
      if (item == limit) {
        setLoader(false);
      }
      return null;
    }
  };

  const getCantidad = () => {
    Constants.calcularDescuento();
    let carrito = JSON.parse(localStorage.getItem(codCliente)) || {};

    let iva = 0;
    let total = 0;
    let descuentoTotal = 0;
    let cantidad = 0;

    Object.keys(carrito).map((item, i) => {
      cantidad += carrito[item]["cantidad"];

      let subtotal = carrito[item]["cantidad"] * carrito[item]["precio"];
      let descuento =
        subtotal *
        (carrito[item]["porcentajeDescuento"]
          ? carrito[item]["porcentajeDescuento"] / 100
          : 0);
      iva +=
        (subtotal - descuento) *
        (props.productosTodos[item].iva
          ? props.productosTodos[item].iva / 100
          : 0);

      descuentoTotal += descuento;
    });

    Object.keys(carrito).map((item, i) => {
      total += carrito[item]["cantidad"] * carrito[item]["precio"];
    });

    setCantidad(cantidad);
    setTotalNavbar(
      Math.round(total) - Math.round(descuentoTotal) + Math.round(iva)
    );
  };

  const handleCargarCarrito = () => {
    if (productos) {
      productos.map((item, i) => {
        if (item.cantidad > 0) {
          if (carrito[item.codigo]) {
            carrito[item.codigo]["cantidad"] = parseInt(item.cantidad);
            carrito[item.codigo]["iva"] = item.iva;
          } else {
            carrito[item.codigo] = {
              nombre: item.nombre,
              precio: item.precio,
              cantidad: parseInt(item.cantidad),
              iva: item.iva,
            };
          }
        }
      });

      localStorage.setItem(codCliente, JSON.stringify(carrito));
      setCarrito(carrito);
      getCantidad();
    }
  };

  useEffect(() => {
    getCantidad();
  }, []);

  return (
    <React.Fragment>
      <div className="navbar principal">
        <Navbar item={4} cantidad={cantidad} totalNavbar={totalNavbar} />
      </div>
      <div className="pedido-list sugerido">
        <div className="product-list">
          <div className="excel">
            <form>
              <div className="align-button">
                <div className="custom-file">
                  <label htmlFor="cargarExcel">Cargar excel</label>
                  <input
                    id="cargarExcel"
                    onChange={(e) => uploadFile(e)}
                    type="file"
                    className="left"
                  />
                </div>
                <ExcelFile
                  element={
                    <a id="descargarPLantilla" className="button download">
                      Descargar plantilla
                    </a>
                  }
                >
                  <ExcelSheet data={columns} name="Leaves">
                    <ExcelColumn label="Código" />
                    <ExcelColumn label="Cantidad" />
                  </ExcelSheet>
                </ExcelFile>
                <div>
                  <a
                    id="agregarCarrito"
                    className="button right"
                    onClick={() => handleCargarCarrito()}
                  >
                    Agregar al carrito
                  </a>
                </div>
              </div>
            </form>
          </div>
          {loader ? (
            <Progress color={"#bf0811"} />
          ) : (
            <React.Fragment>
              {productos
                ? productos
                    .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                    .map((item, i) => (
                      <div className="ui list">
                        <div className="item">
                          <img
                            width="150"
                            className="img-product"
                            src={
                              Constants.URL_IMG_PRODUCTOS +
                              item.codigo.toString().trim() +
                              ".jpg"
                            }
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = noimage;
                            }}
                          ></img>
                          {props.descuentos ? (
                            props.descuentos.filter(
                              (o) => o.codProducto === item.codigo
                            ).length > 0 ? (
                              <img
                                src={descuento}
                                width={50}
                                className="img-descuento"
                              ></img>
                            ) : null
                          ) : null}
                          <div className="content">
                            <a className="header">{item.nombre}</a>
                            <div className="description">
                              <b>Codigo:</b> {item.codigo} - <b>EAN:</b>{" "}
                              {item.ean}
                            </div>
                            {item.cantidad === 0 ? (
                              <div className="description">
                                <b>Cantidad:</b>
                                <h3 style={{ color: "#F91E00" }}>
                                  {item.cantidad}
                                </h3>
                              </div>
                            ) : (
                              <div className="description">
                                <b>Cantidad:</b>
                                <h3 style={{ color: "#5BF900" }}>
                                  {item.cantidad}
                                </h3>
                              </div>
                            )}
                            <div className="description">
                              {Constants.copFormat(
                                item.precio *
                                  (1 + (item.iva ? item.iva : 0) / 100)
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                : null}
            </React.Fragment>
          )}
        </div>
      </div>

      <Tabbar item={4} />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    productosTodos: state.productosTodos,
    descuentos: Object.values(state.descuentos),
  };
};

export default connect(mapStateToProps, {
  traerProductos,
})(Excel);
