import React, { useState, useEffect } from "react";

import axios from "axios";

import * as Constants from "../../constants/global";
import toast from "toasted-notes";

import Progress from "../util/Progress";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const EstadisticasVentas = (props) => {
  let usuario = localStorage.getItem("usuario");
  const [estadisticasxcliente, setEstadisticasxCliente] = useState();

  const [loader, setLoader] = React.useState(false);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const loadDatos = () => {
    setLoader(true);
    let url =
      Constants.URL_BASE +
      Constants.WSESTADISTICASVENTASXCLIENTE +
      "?usuario=" +
      usuario +
      "&fechaInicial=" +
      (props.fechaInicial ? props.fechaInicial : Constants.getDate(0, "-")) +
      "&fechaFinal=" +
      (props.fechaFinal ? props.fechaFinal : Constants.getDate(0, "-"));

    if (props.filtroUsuario) {
      url += "&filtroUsuario=" + props.filtroUsuario;
    }
    if (props.regional) {
      url += "&regional=" + props.regional;
    }
    if (props.agencia) {
      url += "&agencia=" + props.agencia;
    }
    if (props.zona) {
      url += "&zona=" + props.zona;
    }
    
    axios
      .get(url)
      .then((res) => {
        setEstadisticasxCliente(res.data);
        setLoader(false);
      })
      .catch((err) => {
        toast.notify("Error al cargar los datos de estadisticas");
      });
  };

  const exportToExcel = () => {
    let dataExcel = [];
    let fileName = "Seguimiento Clientes";

    Object(estadisticasxcliente).map((item, i) => {
      dataExcel.push({
        "Cod. Cliente": item.codCliente,
        Unidades: item.unidades,
        "Valor Bruto": Constants.copFormat(item.ValorB),
        Descuento: Constants.copFormat(item.descuento),
        "Valor Neto": Constants.copFormat(item.ValorN),
      });
    });

    const ws = XLSX.utils.json_to_sheet(dataExcel);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  useEffect(() => {
    if (props.zona && props.filtroUsuario) {
      loadDatos();
    }
  }, [
    props.fechaInicial,
    props.fechaFinal,
    props.filtroUsuario,
    props.regional,
    props.agencia,
    props.zona,
  ]);

  return (
    <div className="main">
      <div className="cliente-list">
        <div className="product-list">
          {loader ? (
            <Progress color={"#007daf"} />
          ) : (
            <React.Fragment>
              {estadisticasxcliente ? (
                <div className="item" style={{ display: "block" }}>
                  <div className="exportar" style={{ display: "block" }}>
                    <button
                      className="btn-exportar"
                      onClick={(e) => exportToExcel()}
                    >
                      Exportar
                    </button>
                  </div>
                  <table
                    style={{ marginTop: "20px" }}
                    className="table-estadisticas-agendamiento"
                  >
                    <thead>
                      <tr>
                        <th>Cod. Cliente</th>
                        <th>Sucursal</th>
                        <th>Unidades</th>
                        <th>Valor Bruto</th>
                        <th>Descuento</th>
                        <th>Valor Neto</th>
                      </tr>
                    </thead>
                    <tbody>
                      {estadisticasxcliente.map((item, i) => (
                        <tr>
                          <td>{item.codCliente}</td>
                          <td>{item.sucursal}</td>
                          <td>{item.unidades}</td>
                          <td>{Constants.copFormat(item.ValorB)}</td>
                          <td>{Constants.copFormat(item.descuento)}</td>
                          <td>{Constants.copFormat(item.ValorN)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default EstadisticasVentas;
